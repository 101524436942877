export default function Sketch(p5) {
    let canvas;
    let S = 350;


    p5.setup = () => {
        // canvas = p5.createCanvas(400, 400);
        canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight, p5.WEBGL);
        // p5.saveCanvas(canvas, "fld.jpg");
    };

    p5.draw = () => {
        p5.background(40);
        p5.push();
        p5.rotateX(p5.frameCount * 0.01);
        p5.rotateY(p5.frameCount * 0.01);
        p5.noFill();
        p5.stroke("white");
        p5.box(S);
        S = S + 1;
        if (S > 550) {
            S = S - 1;
            if (S < 350) {
                S = S + 1;
            }
        }
        p5.rotateX(p5.frameCount * (-0.01));
        p5.rotateY(p5.frameCount * (-0.01));
        p5.box(250);
        p5.rotateX(p5.frameCount * (-0.005));
        p5.rotateY(p5.frameCount * (-0.005));
        p5.box(175);
        p5.rotateX(p5.frameCount * (0.005) + 1);
        p5.rotateY(p5.frameCount * (0.005) + 1);
        p5.box(75);
        p5.pop();

    };

    p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }


}










